export const interface_reducer = (state = { count: 0, name: null, drawer: false }, action) => {
    switch (action.type) {
        case "INCREMENT_COUNT":
            return {
                ...state,
                count: state.count + 1,
                name: action.payload.name
            };
        case "TOGGLE_DRAWER":
            return {
                ...state,
                drawer: action.payload
            };
        case "SET_POSTS_PAGE":
            return {
                ...state,
                reduxPostsPage: action.payload.reduxPostsPage
            };
        default:
            return state
    }
};
