module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/state/createStore","serialize":{"space":0,"isJSON":true,"unsafe":false,"ignoreFunction":true},"cleanupOnClient":true,"windowKey":"__PRELOADED_STATE__"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","background_color":"#0d1118","theme_color":"#8a8e93","display":"standalone","icon":"src/images/logo_small.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"faede359235e5e3d08f745e2fe160476"},
    }]
