import { createStore, applyMiddleware } from "redux"
import ReduxThunk from 'redux-thunk'
import rootReducer from "../state/rootReducer";

const INIT_STATE = {};

// preloadedState will be passed in by the plugin
export default preloadedState => {
    return createStore(rootReducer, INIT_STATE, applyMiddleware(ReduxThunk));
};
